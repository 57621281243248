<template>
  <div>
    <ui-component-drawer
      v-if="showSideDrawer"
      :drawerTitle="'Warnings'"
      :showDrawer="showSideDrawer"
      :onClickClose="hideDrawer"
      position="right"
      :drawerSize="drawerSize"
    >
      <template v-slot:content>
        <Warnings
          v-if="showWarningsSummary"
          :level="selectedLevel"
          :type="selectedType"
          :warnings="result.Warnings"
        />
      </template>
    </ui-component-drawer>

    <div v-if="isChecking"><Loader /></div>

    <div v-if="result">
      <section class="section is-small">
        <div class="tile is-ancestor has-text-centered">
          <div class="tile is-parent">
            <article
              class="tile is-child box"
              :class="{
                'has-background-danger-light': result.TotalCritical > 0,
                'has-background-warning-light': result.TotalHigh > 0,
              }"
            >
              <a @click="setShowWarningSummary('', '')">
                <p class="title is-size-2">
                  <AnimatedNumber :value="result.TotalWarnings" :speed="5" />
                </p>
                <p class="subtitle is-size-6">Total warnings</p>
              </a>
            </article>
          </div>
        </div>
      </section>

      <section class="section is-small">
        <div class="tile is-ancestor has-text-centered">
          <div class="tile is-parent">
            <article
              @click="setShowWarningSummary('Critical', '')"
              class="tile is-child box clickableBox has-background-danger-light"
            >
              <p class="has-text-weight-bold">
                <AnimatedNumber :value="result.TotalCritical" :speed="5" />
              </p>
              <p class="is-size-7">Critical</p>
            </article>

            <article
              @click="setShowWarningSummary('High', '')"
              class="tile is-child box clickableBox has-background-warning-light"
            >
              <p class="has-text-weight-bold">
                <AnimatedNumber :value="result.TotalHigh" :speed="5" />
              </p>
              <p class="is-size-7">High</p>
            </article>

            <article
              @click="setShowWarningSummary('Medium', '')"
              class="tile is-child box clickableBox"
            >
              <p class="has-text-weight-bold">
                <AnimatedNumber :value="result.TotalMedium" :speed="5" />
              </p>
              <p class="is-size-7">Medium</p>
            </article>

            <article
              @click="setShowWarningSummary('Low', '')"
              class="tile is-child box clickableBox"
            >
              <p class="has-text-weight-bold">
                <AnimatedNumber :value="result.TotalLow" :speed="5" />
              </p>
              <p class="is-size-7">Low</p>
            </article>
          </div>
        </div>
      </section>

      <section class="section is-small">
        <div class="tile is-ancestor has-text-centered">
          <div class="tile is-parent">
            <article
              @click="setShowWarningSummary('', 'Location')"
              class="tile is-child box clickableBox"
            >
              <p class="has-text-weight-bold">
                <AnimatedNumber :value="result.TotalLocation" :speed="5" />
              </p>
              <p class="is-size-7">Location</p>
            </article>
          </div>

          <div class="tile is-parent">
            <article
              @click="setShowWarningSummary('', 'Space')"
              class="tile is-child box clickableBox"
            >
              <p class="has-text-weight-bold">
                <AnimatedNumber :value="result.TotalSpaces" :speed="5" />
              </p>
              <p class="is-size-7">Spaces</p>
            </article>
          </div>

          <div class="tile is-parent">
            <article
              @click="setShowWarningSummary('', 'Option')"
              class="tile is-child box clickableBox"
            >
              <p class="has-text-weight-bold">
                <AnimatedNumber :value="result.TotalOptions" :speed="5" />
              </p>
              <p class="is-size-7">Options</p>
            </article>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import locationProvider from '@/providers/location'

export default {
  components: {
    AnimatedNumber: () => import('@/components/UI/AnimatedNumber'),
    Loader: () => import('@/components/UI/Loader'),
    Warnings: () => import('@/components/UI/Warnings'),
  },

  props: {},

  data() {
    return {
      channelId: this.$route.params.channelId
        ? Number(this.$route.params.channelId)
        : 0,
      locationId: this.$route.params.locationId
        ? Number(this.$route.params.locationId)
        : 0,

      isChecking: false,
      checkSpaces: true,
      checkOptions: true,
      checkVouchers: false,
      result: null,

      showSideDrawer: false,
      showWarningsSummary: false,
      drawerSize: 'normal',
      selectedLevel: '',
      selectedType: '',
    }
  },

  computed: {},

  created() {
    this.checkLocationData()
  },

  methods: {
    checkLocationData() {
      if (!this.isChecking) {
        this.isChecking = true

        locationProvider.methods
          .checkLocation(
            this.channelId,
            this.locationId,
            this.checkSpaces,
            this.checkOptions,
            this.checkVouchers
          )
          .then((response) => {
            if (response.status === 200) {
              this.result = response.data
            }
          })
          .finally(() => {
            this.isChecking = false
          })
      }
    },

    setShowWarningSummary(level = '', type = '') {
      this.selectedLevel = level
      this.selectedType = type
      this.showSideDrawer = true
      this.showWarningsSummary = true
    },

    hideDrawer() {
      this.selectedLevel = ''
      this.selectedType = ''
      this.showSideDrawer = false
      this.showWarningsSummary = false
    },
  },
}
</script>

<style>
.clickableBox:hover {
  cursor: pointer;
}
</style>
